import {useOutlet} from 'reconnect.js';
import {useCallback, useEffect, useState} from 'react';
import * as L from '../Utils/Lang';
import Config from '../../data.json';

export const useMealDetail = ({cinemaId, id}) => {
  const [actions] = useOutlet('actions');
  const [meal, setMeal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchMeal = useCallback(async () => {
    try {
      setIsLoading(true);
      const resp = await actions.fetchMealList({cinemaId: cinemaId});
      const m = resp.find((m) => m.Id === id);
      setMeal({
        ...m,
        id: m.Id,
        title: L.d({zh: m.DescriptionAlt, en: m.Description}),
        subtitle: L.d({
          zh: m.ExtendedDescriptionAlt,
          en: m.ExtendedDescription,
        }),
        priceInCents: m.PriceInCents,
        restrictToLoyalty: m.RestrictToLoyalty,
        packageChildItems: m.PackageChildItems,
        isPackage: m.PackageChildItems.length > 0,
        imgUrl: `${Config.mealImageCdn}${m.Id}`,
      });
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsLoading(false);
    }
  }, [actions, cinemaId, id]);

  useEffect(() => {
    fetchMeal().then(() => 0);
  }, [cinemaId, fetchMeal]);

  return {
    meal,
    isLoading,
  };
};
