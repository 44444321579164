import React, {useCallback, useState} from 'react';
import styled from 'styled-components';
import Spinner from '../../Components/Spinner';
import qs from 'query-string';
import {useMealDetail} from '../../Hooks/useMealDetail';
import {Counter} from '../Book/Counter';
import {RectButton} from '../../Components/RectButton';
import {useOutlet} from 'reconnect.js';
import SingleMeal from '../../images/temp/ticket-meal.png';
import {
  Card,
  FlexColumnCenterCenter,
  FlexRowCenterCenter,
  FlexRowCenterStart,
  Text22,
} from '../../widget';
import {SectionHeader} from '../Landing/SectionHeader';
import {BOOKING_MODE, Color, ORDER_STATUS} from '../../constants';
import {useOrder} from '../../Hooks/useOrder';
import {getFormat} from '../../Utils/Price';
import {navigate} from 'gatsby-link';
import {ConfirmModalContent} from '../../Components/ConfirmModalContent';
import useModal from '../../Hooks/useModal';
import {aggregateConcessions, formatSessions} from '../../Utils/Order';
import {AlertModalContent} from '../../Components/AlertModalContent';

const MEAL_CHECKOUT_FLOW_STEPS = {
  quantity: 'quantity',
  recipe: 'recipe',
};

function MealDetailRecipe(props) {
  const {orderId, meal} = props;
  const {order, isLoading: isOrderLoading} = useOrder(orderId);

  const onSubmit = async () => {
    await navigate(`/checkout?id=${orderId}`);
  };

  if (isOrderLoading) {
    return <Spinner />;
  }
  return (
    <div className="view">
      <StyledRecipe>
        <SectionHeader title="交易明細" caption="recipe" />
        <div className="dashed-sep" />
        <div className="img-container">
          <img src={meal.imgUrl || SingleMeal} alt="meal" />
        </div>
        <div className="dashed-sep" />
        {order.aggregateConcessions.map((c, i) => {
          const targetConcession = order.concessions_info.find(
            (ci) => ci.id.toString() === c.id,
          );
          return (
            <div key={i} className="ticket-property">
              <div className="name">{targetConcession?.title}</div>
              <div className="quantity">x{c.quantity}</div>
              <div className="price">
                {getFormat(c.quantity * c.finalPriceCents)}
              </div>
            </div>
          );
        })}
        <div className="dashed-sep" />
        <div className="ticket-property">
          <div className="name">總計</div>
          <Text22
            style={{
              color: Color.orange,
              fontWeight: 'bold',
              width: '100%',
              textAlign: 'right',
            }}>
            {getFormat(order.vista_order.TotalValueCents)}
          </Text22>
        </div>
      </StyledRecipe>
      <div style={{flex: 1}} />
      <RectButton style={{width: '100%'}} onClick={onSubmit}>
        信用卡付款
      </RectButton>
    </div>
  );
}

const MealDetail = (props) => {
  const {location} = props;
  const {id} = qs.parse(location.search);
  const [actions] = useOutlet('actions');
  const [user] = useOutlet('user');
  const {meal, isLoading: isMealLoading} = useMealDetail({
    cinemaId: 1001,
    id: id,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [curStep, setCurStep] = useState(MEAL_CHECKOUT_FLOW_STEPS.quantity);
  const [cart, setCart] = useState([]);
  const [orderId, setOrderId] = useState('');

  const {modalRef, modalElem} = useModal();

  const fetchOrderList = useCallback(async () => {
    try {
      const resp = await actions.fetchOrderList();
      return resp.map((o) => {
        return {
          ...o,
          isFilmOrder: !!o.vista_order.Sessions,
          sessions: formatSessions(o.vista_order.Sessions, o.films_info?.[0]),
          aggregateConcessions: aggregateConcessions(o.vista_order.Concessions),
        };
      });
    } catch (e) {
      console.log('debug', e);
      return [];
    } finally {
    }
  }, [actions]);

  const onAddMeal = (m) => {
    const targetIndex = cart.findIndex((meal) => meal.id === m.id);
    if (targetIndex > -1) {
      const nextMeals = [...cart];
      nextMeals[targetIndex].quantity++;
      setCart(nextMeals);
    } else {
      const nextMeals = [...cart, {id: m.id, quantity: 1}];
      setCart(nextMeals);
    }
  };

  const onRemoveMeal = (m) => {
    const targetIndex = cart.findIndex((meal) => meal.id === m.id);
    const curQuantity = cart.find((meal) => meal.id === m.id)?.quantity || 0;
    if (curQuantity - 1 < 0) {
      return;
    }
    if (targetIndex > -1) {
      const nextMeals = [...cart];
      nextMeals[targetIndex].quantity--;
      setCart(nextMeals.filter((m) => m.quantity > 0));
    }
  };

  const onCreateMealOrder = async () => {
    try {
      if (!user) {
        await navigate('/login');
        return;
      }
      setIsLoading(true);
      const orderList = await fetchOrderList();
      const withPendingOrder = orderList.find(
        (o) =>
          o.status === ORDER_STATUS.PAYMENT_WAITING ||
          o.status === ORDER_STATUS.PAYMENT_FAIL,
      );
      if (withPendingOrder) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <ConfirmModalContent
              title="尚有訂單未付款"
              subtitle="請先完成前一筆未付款訂單"
              onConfirm={async () => {
                await navigate(`/checkout?id=${withPendingOrder.id}`);
              }}
              onCancel={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
        return;
      }

      if (cart.length <= 0) {
        modalRef.current.open();
        modalRef.current.set({
          elem: (
            <AlertModalContent
              title="餐點數量至少一份"
              subtitle="請調整餐點數量"
              onConfirm={() => {
                modalRef.current.close();
              }}
            />
          ),
        });
        return;
      }

      const values = {
        CinemaId: '1001',
        UserSessionId: '',
        Concessions: cart.map((m) => ({
          ItemId: m.id,
          Quantity: m.quantity,
          RecognitionId: '',
          RecognitionSequenceNumber: 0,
        })),
        ReturnOrder: true,
        SessionId: '',
        GiftStoreOrder: false,
        ConcessionsInfo: [
          {
            id: meal.id,
            title: meal.title,
            subtitle: meal.subtitle,
            imgUrl: meal.imgUrl,
          },
        ],
        BookingMode: BOOKING_MODE.paid,
      };
      const resp = await actions.createMealOrder(values);
      setOrderId(resp.OrderId);
      setCurStep(MEAL_CHECKOUT_FLOW_STEPS.recipe);
    } catch (e) {
      console.log('debug', e);
    } finally {
      setIsLoading(false);
    }
  };

  if (isMealLoading) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      {curStep === MEAL_CHECKOUT_FLOW_STEPS.quantity && (
        <div className="view">
          <img src={meal.imgUrl || SingleMeal} alt="meal" />
          <div className="context">
            <div className="title">{meal.title}</div>
            <div className="subtitle">{meal.subtitle}</div>
            <div className="price">{getFormat(meal.priceInCents)}</div>
          </div>
          <div style={{flex: 1}} />
          <div className="counter-container">
            <div className="label">數量</div>
            <Counter
              style={{margin: '10px 0'}}
              onPlus={() => {
                onAddMeal(meal);
              }}
              onMinus={() => {
                onRemoveMeal(meal);
              }}
              quantity={cart.find((m) => m.id === meal.id)?.quantity || 0}
            />
          </div>
          <RectButton
            isLoading={isLoading}
            style={{width: '100%'}}
            onClick={onCreateMealOrder}>
            購買
          </RectButton>
        </div>
      )}
      {curStep === MEAL_CHECKOUT_FLOW_STEPS.recipe && (
        <MealDetailRecipe meal={meal} orderId={orderId} />
      )}
      {modalElem}
    </Wrapper>
  );
};

const StyledRecipe = styled(Card)`
  width: 100%;
  & > .img-container {
    width: 100%;
    ${FlexRowCenterCenter};
    & > img {
      width: 210px;
      height: 210px;
      object-fit: contain;
    }
  }
  & .dashed-sep {
    height: 1px;
    width: 100%;
    border-bottom: 1px dashed ${Color.black_50};
    margin: 10px 0;
  }

  & .ticket-property {
    width: 100%;
    ${FlexRowCenterStart};
    text-align: left;
    & > .name {
      min-width: 200px;
    }
    & > .price {
      text-align: right;
      width: 100%;
    }
  }
`;

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0 auto;
  max-width: var(--contentMaxWith);
  & > .view {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: var(--basePadding);
    min-height: calc(100vh - var(--topNavBarHeight));
    ${FlexColumnCenterCenter};
    & > .context {
      width: 100%;
      ${FlexColumnCenterCenter};
      text-align: center;
      & > .title {
        font-size: 22px;
        font-weight: bold;
        color: ${Color.black_70};
      }
      & > .subtitle {
        font-size: 12px;
        color: ${Color.black_70};
        margin-bottom: 20px;
        max-width: 200px;
      }
      & > .price {
        font-size: 22px;
        font-weight: bold;
        color: ${Color.orange};
      }
    }
    & > img {
      width: 280px;
      height: 280px;
      object-fit: contain;
      background-color: ${Color.orange};
      border-radius: 10px;
    }
    & > .counter-container {
      ${FlexColumnCenterCenter};
    }
  }
`;

export default MealDetail;
